import axios from "axios";

export const SubirMedia = async (imagen, type, nombre, idclinica, ruta) => {
    const url = "https://colon.smartmedic.site/backend/upload?idclinica="+idclinica+"&ruta="+ruta;
    const formData = new FormData();
    if(type){
        formData.append("file", imagen, nombre);
        const { data, status } = await axios.post(url, formData);
        if (status === 200 && data.success) {
            return data.data[0].link;
        }else{
            return null;
        }
    }else{
        formData.append("file", imagen);
        const { data, status } = await axios.post(url, formData);
        if (status === 200 && data.success) {
            return data.data[0].link;
        }else{
            return null;
        }
    
    }
}
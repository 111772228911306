/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { EliminarCompany, ObtenerCompanies } from "../../../Service/Service.compania";
import Speener from "../../Speener/Speener";
import CompanyCreate from "./CompanyCreate";

export default function Company({tabs}) {
  const [show, setShow] = useState(false);
  const [editarCompany, setEditCompany] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ping, setPing] = useState(false)
  const [listCompany, setListCompany] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
      (async()=>{
        setLoading(true)
        const response = await ObtenerCompanies()
        if(response){
            setListCompany(response)
            setLoading(false)
        }
      })()

  }, [ping, tabs.company])

  useEffect(() => {
    if(show === false){
      setEditCompany(null)
    }
  }, [show])

  const EdidarCompania = (company) => {
    setEditCompany(company)
    handleShow()
  }

  const DeleteComapany = async(idcompany) => {
    const response = await EliminarCompany(idcompany)
    if(response){
      setPing(!ping)
    }
  }


  return (
    <section>
      <div class="row">
        <div class="col-12">

          <div class="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" class="btn btn-light waves-effect waves-light" onClick={handleShow}><i class="bx bx-plus me-1"></i> Crear Company</button>
            <Speener show={loading}/>
          </div>


          <table id="tb_clinicas" class="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Compania</th>
                <th>Direccion</th>
                <th>Email</th>
                <th>Pais</th>
                <th>Cant. Clinica</th>
                <th>Cant. Doctores</th>
                <th>Cant. Administradores</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>

                {listCompany.map((company, index) => (
                    <tr key={index}>
                        <td align="center">{index+1}</td>
                        <td>{company.company}</td>
                        <td>{company.direccion}</td>
                        <td>{company.email}</td>
                        <td>{company.pais}</td>
                        <td>{company.cant_clinicas}</td>
                        <td>{company.cant_doctores}</td>
                        <td>{company.cant_administrativo}</td>
                        <td align="left">
                            <div class="btn-group"><button type="button"
                                class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                                aria-expanded="false"><i class="bx bx-dots-horizontal-rounded"></i></button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#;" onClick={()=>EdidarCompania(company)} >Editar</a>
                                    <a class="dropdown-item" href="#;" onClick={()=>DeleteComapany(company.idcompany)}>Eliminar</a>
                                    <div class="dropdown-divider"></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Company
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CompanyCreate 
            setPing={setPing}
            setShow={setShow}
            editarCompany={editarCompany}
          />
        </Offcanvas.Body>
      </Offcanvas>

    </section>
  );
}

import { BmHttp } from "../utils/Global";


export const ControllerCreateCliente = async (cliente) => {
    try {
        const { data } = await BmHttp().post('cliente', cliente)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerListarClientes = async (idclinica) => {
    try {
        const { data } = await BmHttp().get(`cliente_clinica?idclinica=${idclinica}`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerSchearCliente = async (idclinica, schear) => {
    try {
        const { data } = await BmHttp().get(`cliente_schear?idclinica=${idclinica}&schear=${schear}`)
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerEliminarCliente = async (idcliente) => {
    try {
        const { data } = await BmHttp().delete('cliente',{
            idcliente: idcliente
        })
        return data
    } catch (error) {
        console.log(error);
    }
}

export const ControllerActualizarCliente = async (cliente) => {
    try {
        const { data } = await BmHttp().post('cliente', cliente)
        return data
    } catch (error) {
        console.log(error);
    }
}
import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  ControllerListarPacientesCitasTake,
  ListarPacientes
} from "../Service/Service.paciente";

import CrearCliente from "../Components/Cliente/CrearCliente";
import ModalNuevoProcedimiento from "../Components/ModeGeneral/ModalProcedimientos/ModalNuevoProcedimiento";
import ModalProcedimientosPendientes from "../Components/ModeGeneral/ModalProcedimientos/ModalProcedimientosPendientes";
import { ObtenerClinica } from "../Service/Serive.clinica";
import { ControllerSchearCliente } from "../Service/Service.cliente";
import { ActualizarCantidadProcedimientoFactura, DeleteAllProcedimientoFactura, DeleteProcedimientoFactura, GetProcedimientoFactura } from "../Service/Service.Store";
import { ListarMetoPago } from "../Service/Service.util";
import { RegistrandofacturaCliente } from "../Service/Services.Facturacion";
import { GetTokenDecoded } from "../utils/storeUsuario";

export default function FacturaCrear() {
  const [pacientes, setPacientes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [valueCliente, setValueCliente] = useState(null);
  const [idpaciente, setIdPaciente] = useState(null);
  const [localPing, setLocalPing] = useState(false);
  const [totales, setTotales] = useState({ total: 0.00, iva: 0.00 });
  const [metodoPago, setMetodoPago] = useState([]);
  const [facturacionCliente, setFacturacionCliente] = useState({
    idclinica: undefined,
    idcita: undefined,
    idpaciente: undefined,
    idcliente: undefined,
    idmetodo_pago: undefined,
    estado: "Pendiente",
    total: 0.00,
    impuesto: 0.00,
    subtotal: 0.00,
    descuento: 0.00,
    observacion: "",
    paga: 0.00,
    pagos: [],
  });


  const [procedimientos, setProcedimientos] = useState([]);
  const [pacienteSeleccionado, setPacienteSeleccionado] = useState(null);
  const [clinica, setClinica] = useState(null);
  const [totalFactura, setTotalFactura] = useState(0);
  const [ultimaCita, setUltimaCita] = useState(null);
  const [cantPagar, setCantPagar] = useState("0.00");
  const [pagos, setPagos] = useState([]);
  const [valorPendiente, setValorPendiente] = useState(0);
  const [showCliente, setShowCliente] = useState(false);
  const [ping, setPing] = useState(false);
  const [cliente, setCliente] = useState({
    idcliente: 0,
    idclinica: 0,
    nombre: "",
    tipoID: "",
    identificacion: "",
    es_juridico: false,
    direccion: "",
    correo: "",
    celular: "",
    telefono: "",
  });


  const [showProcedimientoPendientes, setShowProcedimientoPendientes] = useState(false);
  const [shoNuevoProcedimiento, setShoNuevoProcedimiento] = useState(false);

  useEffect(() => {
    const obtenerPacientes = async () => {
      const tokenDecoded = GetTokenDecoded();
      if (tokenDecoded && tokenDecoded.idclinica) {
        const response = await ListarPacientes(tokenDecoded.idclinica);
        if (response && response.success) {
          // Transformar los datos para react-select
          const pacientesOptions = response.data.map((p) => ({
            value: p.idpaciente,
            label: p.nombres_completos,
            ...p,
          }));
          setPacientes(pacientesOptions);
        }
        const cl = await ControllerSchearCliente(tokenDecoded.idclinica, "");
        if (cl && cl.success) {
          const clientesOptions = cl.data.map((c) => ({
            value: c.idcliente,
            label: c.nombre,
            ...c,
          }));
          setClientes(clientesOptions);
        }
      }
    };

    const total = calcularTotalFactura(procedimientos);
    setTotalFactura(total);

    obtenerPacientes();
    obtenerInfoClinica();
  }, [ping]);

  useEffect(() => {
    const nuevoPendiente = Math.max(
      0,
      parseFloat(totalFactura) - calcularTotalPagado()
    );
    setValorPendiente(nuevoPendiente);
    setCantPagar(nuevoPendiente.toFixed(2));
  }, [totalFactura, pagos]);


  const handleCloseCliente = () => {
    setShowCliente(false);
  }

  const handleCloseProcedimientoNuevo = () => {
    setShoNuevoProcedimiento(!shoNuevoProcedimiento);
  }

  const handleCloseProcedimientoPendientes = () => {
    setShowProcedimientoPendientes(!showProcedimientoPendientes);
  }


  const calcularTotalPagado = () => {
    return pagos.reduce((sum, pago) => sum + parseFloat(pago.valor), 0);
  };

  const agregarPago = () => {
    const valorPago = totales.total

    if (isNaN(valorPago) || valorPago <= 0) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "El valor a pagar debe ser un número mayor que cero",
      });
      return;
    }
  };

  const eliminarPago = (index) => {
    const nuevosPagos = pagos.filter((_, i) => i !== index);
    setPagos(nuevosPagos);

    // Recalcular el valor pendiente
    const totalPagado = nuevosPagos.reduce((sum, pago) => sum + pago.valor, 0);
    const nuevoPendiente = parseFloat(totalFactura) - totalPagado;
    setValorPendiente(nuevoPendiente);
  };

  const handleCantPagarChange = (e) => {
    setCantPagar(e.target.value);
  };

  // const isFacturaButtonEnabled = () => {
  //   const pendiente = parseFloat(totalFactura) - calcularTotalPagado();
  //   return Math.abs(pendiente) < 0.01; // Usamos una pequeña tolerancia para evitar problemas con números flotantes
  // };

  const handlePacienteChange = (selectedOption) => {
    if (selectedOption) {
      // Simular un evento para mantener la compatibilidad con pacienteInfo
      const event = {
        target: {
          value: selectedOption.value.toString(),
        },
      };
      pacienteInfo(event);
    } else {
      // Limpiar la selección
      const event = {
        target: {
          value: "",
        },
      };
      pacienteInfo(event);
    }
  };

  // para cliente
  const handleClientesChange = async(selectedOption) => {
    console.log("selectedOption: ", selectedOption);
    setValueCliente(selectedOption);
    setFacturacionCliente({ ...facturacionCliente, idcliente: selectedOption.idcliente });
    // if (selectedOption) {
    //   const tokenDecoded = GetTokenDecoded();
    //   const result = await ControllerSchearCliente(tokenDecoded.idclinica, selectedOption.value);
    //   if(result && result.success){
    //     setCliente(result.data[0]);
    //   }
    // } else {
    //   setCliente(null);
    // }
  }

  // buscar cliente
  const buscarCliente =  (schear) => {
    if(schear.length > 3){
      const tokenDecoded = GetTokenDecoded();
      ControllerSchearCliente(tokenDecoded.idclinica, schear).then((result) => {
          if(result && result.success){
            const clientesOptions = result.data.map((c) => ({
              value: c.idcliente,
              label: c.nombre,
              ...c,
            }));
            setClientes(clientesOptions);
          }
      })
        
    }
  }

  const calcularTotalFactura = (procs) => {
    const total = procs.reduce((acc, proc) => {
      const valor =
        parseFloat(proc.valorPagar) ||
        parseFloat(proc.precio || 0) * parseFloat(proc.cantidad || 0);
      return acc + valor;
    }, 0);
    return total.toFixed(2);
  };

  const pacienteInfo = async (event) => {
    const pacienteId = event.target.value;
    const pacienteSeleccionado = pacientes.find(
      (p) => p.idpaciente.toString() === pacienteId
    );
    console.log("pacienteSeleccionado: ", pacienteId);
    if(pacienteId){
      setIdPaciente(pacienteId);
    }

    if (pacienteSeleccionado) {
      setPacienteSeleccionado(pacienteSeleccionado);

      // Actualizar la información en la UI
      document.getElementById("sp_c_nombre").textContent =
        pacienteSeleccionado.nombres_completos;
      document.getElementById("sp_c_doc").textContent =
        pacienteSeleccionado.cedula;
      document.getElementById("sp_c_telefono").textContent =
        pacienteSeleccionado.celular;
      document.getElementById("sp_c_correo").textContent =
        pacienteSeleccionado.email;

      // Mostrar la tarjeta de información del paciente
      document.getElementById("card-paciente-info").style.display = "block";

      const response = await ControllerListarPacientesCitasTake(
        pacienteSeleccionado.value
      );

      if (response) {
        const ultimaCita = response.data;
        setUltimaCita(ultimaCita);
        // const procsCita = ultimaCita.procedimiento_cita_paciente.map(
        //   (proc) => ({
        //     ...proc,
        //     valorPagar: (
        //       parseFloat(proc.precio || 0) * parseFloat(proc.cantidad || 0)
        //     ).toFixed(2),
        //   })
        // );

        // setProcedimientos(procsCita);
        // const total = calcularTotalFactura(procsCita);
        // setTotalFactura(total);
      } else {
        console.log("No se encontraron citas o procedimientos");
        setProcedimientos([]);
        setTotalFactura(0);
      }
    } else {
      setPacienteSeleccionado(null);
      setTotalFactura(0);
      setProcedimientos([]);
      // Ocultar la tarjeta de información del paciente si no hay selección
      document.getElementById("card-paciente-info").style.display = "none";
    }
  };

  const handleValorPagarChange = (index, newValue) => {
    const updatedProcedimientos = procedimientos.map((proc, i) =>
      i === index ? { ...proc, valorPagar: newValue } : proc
    );
    setProcedimientos(updatedProcedimientos);

    const newTotal = calcularTotalFactura(updatedProcedimientos);
    setTotalFactura(newTotal);
  };

  const obtenerInfoClinica = async () => {
    const tokenDecoded = GetTokenDecoded();
    if (tokenDecoded && tokenDecoded.idclinica) {
      const response = await ObtenerClinica(tokenDecoded.idclinica);
      if (response && response.success && response.data.length > 0) {
        setClinica(response.data[0]);
      }
    }
  };


  const CalculoPrecios = (procedimientos) => {
    if(procedimientos){
      let total = 0;
      let subtotal = 0;
      let impuesto = 0;
      let totalPagar = 0;
      let iva = 0;
      procedimientos.forEach((proc) => {
        subtotal = parseFloat(proc.precio) * parseFloat(proc.cantidad);
        impuesto = subtotal * 0.12;
        totalPagar = subtotal + impuesto;
        total += totalPagar;
        iva += impuesto;
      });
      console.log("Totales:", total);
      console.log("IVA:", iva);
      setTotales({
        total: total.toFixed(2),
        iva: iva.toFixed(2),
      });
    }else{
      setTotales({
        total: 0.00,
        iva: 0.00,
      })
    }
  }

  useEffect(() => {
    if(pacientes.length ===0 ){
      // eliminamos lo que hay en el localstorage
      DeleteAllProcedimientoFactura();
    }else{
      setProcedimientos(GetProcedimientoFactura());
      CalculoPrecios(GetProcedimientoFactura());
    }
  }, [localPing]);

  useEffect(() => {
    (async() => {
      const result = await ListarMetoPago();
      if(result && result.success){
        setMetodoPago(result.data);
      }
    })();
  }, [procedimientos]);


  // registrar factura
  const Registrandofactura = async () => {
    let info = {
      ...facturacionCliente,
      idpaciente: idpaciente,
      idcita: ultimaCita.idcita,
      idclinica: clinica.idclinica,
      pagos: GetProcedimientoFactura(),
      total: totales.total,
      impuesto: totales.iva,
      subtotal: totales.total - totales.iva,
    }
    console.log("Factura: ", info);
    const response = await RegistrandofacturaCliente(info);
    if(response && response.success){
      Swal.fire({
        icon: "success",
        title: "Factura Ingresada",
        text: "La factura se ingreso correctamente",
      });
      DeleteAllProcedimientoFactura();
      setLocalPing(Math.random());
    }else{
      Swal.fire({
        icon: "error",
        title: "Error",
        text: response.message || "Hubo un error al ingresar la factura",
      });
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Crear Facturas</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="#">Facturas</a>
                    </li>
                    <li className="breadcrumb-item active">Crear</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="form-group col-md-3">
                    <label for="inputEmail4">Clinicas</label>
                    <select
                      className="form-select"
                      id="fc_idclinica"
                      name="fc_idclinica"
                    >
                      {clinica ? (
                        <option value={clinica.idclinica}>
                          {clinica.nombre}
                        </option>
                      ) : (
                        <option value="">Cargando...</option>
                      )}
                    </select>
                  </div>

                  <div className="form-group col-md-6 ">
                    <label for="inputEmail4">Paciente</label>

                    <div className="row">
                      <div className="col-10">
                        <Select
                          id="fc-paciente"
                          name="fc-paciente"
                          options={pacientes}
                          onChange={handlePacienteChange}
                          placeholder="Buscar paciente..."
                          isClearable
                        />
                      </div>
                      <div className="col-2">
                        <button
                          id="btn_emptyPaciente"
                          type="button"
                          onclick="validateEmptyInfoPaciente()"
                          style={{ display: "none" }}
                          className="btn btn-soft-light waves-effect waves-light"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-9 mt-2">
                    <label for="input_datos_facturas">Facturar otros Datos:</label>
                    <div className="row">
                      <div className="col-8">
                        <Select
                          id="input_datos_facturas"
                          name="input_datos_facturas"
                          value={valueCliente}
                          onChange={handleClientesChange}
                          onInputChange={buscarCliente}
                          options={clientes}
                          placeholder="Buscar cliente..."
                          isClearable
                        />
                      </div>
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn btn-soft-light waves-effect waves-light"
                          onClick={() => setShowCliente(true)}
                        >
                          <i className="fas fa-user-plus"></i>
                        </button>
                        <button
                          id="bn_emptyCliente"
                          type="button"
                          className="btn btn-soft-light waves-effect waves-light"
                          
                          style={{ display: "none" }}
                        >
                          
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="row">
              <div
                id="card-paciente-info"
                className="col-12"
                style={{ display: "none" }}
              >
                <div
                  className="card border border-primary"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="card-body" style={{ padding: "0.7rem 1.05rem" }}>
                    <span className="card-subtitle text-muted">
                      <strong>PACIENTE:</strong>{" "}
                    </span>{" "}
                    <span
                      id="sp_c_nombre"
                      style={{ fontSize: "17px", fontWeight: "lighter" }}
                    ></span>
                    <br />
                    <small className="text-muted">
                      <strong>
                        <i className="far fa-address-card"></i>
                      </strong>{" "}
                      <span id="sp_c_doc"></span>
                    </small>{" "}
                    &nbsp;&nbsp;
                    <small className="text-muted">
                      <strong>
                        <i className="fas fa-mobile-alt"></i>
                      </strong>{" "}
                      <span id="sp_c_telefono"></span>
                    </small>
                    <br />
                    <small className="text-muted">
                      <i className="fas fa-envelope"></i>{" "}
                      <span id="sp_c_correo"></span>
                    </small>
                    <br />
                  </div>
                </div>
              </div>
              <div
                id="card-cliente-info"
                className="col-12"
                style={{ display: "none" }}
              >
                <div
                  className="card border border-success"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="card-body" style={{ padding: "0.7rem 1.05rem" }}>
                    <span className="card-subtitle text-muted">
                      <strong>CLIENTE:</strong>{" "}
                    </span>{" "}
                    <span
                      id="sp_cl_nombre"
                      style={{ fontSize: "17px", fontWeight: "lighter" }}
                    ></span>
                    <br />
                    <small className="text-muted">
                      <strong>
                        <i className="far fa-address-card"></i>
                      </strong>{" "}
                      <span id="sp_cl_doc"></span>
                    </small>{" "}
                    &nbsp;&nbsp;
                    <small className="text-muted">
                      <strong>
                        <i className="fas fa-mobile-alt"></i>
                      </strong>{" "}
                      <span id="sp_cl_telefono"></span>
                    </small>
                    <br />
                    <small className="text-muted">
                      <i className="fas fa-envelope"></i>{" "}
                      <span id="sp_cl_correo"></span>
                    </small>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="mb-2">
            <div className="row">
              <div className="col-5">
                <button
                  type="button"
                  className="btn btn-soft-primary waves-effect btn-label waves-light mx-1"
                  onClick={() => {
                    if (idpaciente) {
                      handleCloseProcedimientoNuevo(); 
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: "Atención",
                        text: "Por favor, seleccione un paciente primero",
                      });
                    }
                  }}
                >
                  <i className="bx bx-plus label-icon"></i> Nuevo Procedimiento
                </button>

                <button
                  type="button"
                  className="btn btn-soft-secondary waves-effect btn-label waves-light"
                  onClick={() => {
                    if (idpaciente) {
                      handleCloseProcedimientoPendientes(); 
                    } else {
                      Swal.fire({
                        icon: "warning",
                        title: "Atención",
                        text: "Por favor, seleccione un paciente primero",
                      });
                    }
                  }}
                >
                  <i className="bx bx-plus label-icon"></i> Procedimientos Pendientes
                </button>
              </div>
              

              <div className="col-1">
                <div
                  className="spinner-border spin_loading text-primary m-1"
                  role="status"
                  style={{ display: "none" }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-9">
            <div className="card">
              <div className="card-body">
                <table id="tb_factura" className="table">
                  <thead className="upper br-sm br-white">
                    <tr className="bg-grey-l">
                      <th>
                        <div align="center">#</div>
                      </th>
                      <th style={{ textAlign: "left" }}>Procedimiento</th>
                      <th style={{ textAlign: "left" }}>Tipo</th>
                      <th>
                        <div align="center">Cant</div>
                      </th>
                      <th>
                        <div align="center">PVP</div>
                      </th>
                      {/* <th>
                        <div align="center">
                          <div align="center">Valor Pagar</div>
                        </div>
                      </th> */}
                      <th>
                        <div align="center">Impuesto</div>
                      </th>
                      <th>
                        <div align="center">Total Pagar</div>
                      </th>
                      <th>
                        <div align="center">Acciones</div>
                      </th>
                      <th style={{ display: "none" }}> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {procedimientos && procedimientos.length > 0 ? (
                      procedimientos.map((proc, index) => (
                        <tr key={proc.idprocedimiento_cita_paciente || index}>
                          <td align="center">{index + 1}</td>
                          <td>{proc.ExamenTipo?.descripcion || "N/A"}</td>
                          <td>{proc.ExamenTipo?.tipo || "N/A"}</td>
                          <td align="center">
                            {
                              // que se pueda actualizar la cantidad
                              // {proc.cantidad || "0"}
                              <input
                                type="number"
                                className="form-control"
                                value={proc.cantidad || 0}
                                onChange={(e) => {
                                  if(e.target.value <= 0){
                                    e.target.value = 1;
                                  }
                                  ActualizarCantidadProcedimientoFactura(proc.idprocedimiento_cita_paciente, parseInt(e.target.value));
                                  setLocalPing(Math.random());
                                }}
                                style={{ width: "80px", textAlign: "right" }}
                              />
                            }
                          </td>
                          <td align="center">{proc.precio || "0.00"}</td>
                          {/* <td align="center">
                            <input
                              type="number"
                              className="form-control"
                              value={proc.valorPagar || 0}
                              onChange={(e) =>
                                handleValorPagarChange(index, e.target.value)
                              }
                              style={{ width: "80px", textAlign: "right" }}
                            />
                          </td> */}
                          <td align="center">0.00</td>
                          <td align="center">
                            {(
                              parseFloat(proc.precio || 0) *
                              parseFloat(proc.cantidad || 0)
                            ).toFixed(2)}
                          </td>
                          <td align="center">
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => {
                                DeleteProcedimientoFactura(proc.idprocedimiento_cita_paciente);
                                setLocalPing(Math.random());
                              }}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" align="center">
                          No hay procedimientos para mostrar
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="col-3">
            <div className="card">
              <div className="card-body">
                <table className="table table-sm">
                  <tr>
                    <td align="right">
                      <strong>SUBTOTAL</strong>
                    </td>
                    <td align="right">
                      <span
                        id="sp_subtotal_factura"
                        style={{ color: "green", fontSize: "17px" }}
                      >
                        {totales.total - totales.iva}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td align="right">
                      <strong>IMPUESTO</strong>
                    </td>
                    <td align="right">
                      <span
                        id="sp_iva_fact"
                        style={{ color: "blue", fontSize: "17px" }}
                      >
                        {totales.iva}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td align="right">
                      <strong>TOTAL</strong>
                    </td>
                    <td align="right">
                      <strong>
                        <span
                          id="sp_total_factura"
                          style={{ color: "red", fontSize: "20px" }}
                        >
                          {totales.total}
                        </span>
                      </strong>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Metodos de Pago</h4>

                <div className="row">
                  <div className="form-group col-md-12 mb-2">
                    <select
                      id="metodopago"
                      className="form-select"
                      data-placeholder="seleccione forma de pago"
                      value={facturacionCliente.idmetodo_pago}
                      onChange={(e) => setFacturacionCliente({ ...facturacionCliente, idmetodo_pago: parseInt(e.target.value) })}
                    >
                      <option value="">Agregar forma de Pago</option>
                      {
                        metodoPago.map((metodo, index) => (
                          <option key={index} value={metodo.idmetodo_pago} selected={facturacionCliente.idmetodo_pago === metodo.idmetodo_pago}
                          >{metodo.metodo}</option>
                        ))
                      }
                    </select>
                  </div>

                  <div id="dv_efectivo" className="form-row mb-3">
                    <div className="form-group col-md-12">
                      <label className="control-label" for="cantidad">
                        Valor a Pagar ($)
                      </label>
                      <input
                        id="cant_pagar"
                        className="form-control"
                        type="number"
                        value={facturacionCliente.paga}
                        onChange={(e) => setFacturacionCliente({ ...facturacionCliente, paga: parseFloat(e.target.value) })}
                      />
                    </div>
                    {/* {!isFacturaButtonEnabled() && (
                      <div className="text-danger mt-2">
                        El total pagado debe ser igual al total de la factura
                        para poder ingresarla.
                      </div>
                    )} */}
                  </div>

                  <div align="right">
                    <button
                      onClick={agregarPago}
                      type="button"
                      className="btn btn-primary constant"
                    >
                      Agregar Pago
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-6">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Pendiente:
                  <span
                    id="valor_pendiente"
                    style={{
                      fontSize: "20px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {(parseFloat(totalFactura) - calcularTotalPagado()).toFixed(2)}
                  </span>
                </h4>

                <table
                  id="tb_formapago"
                  width="30%"
                  style={{ fontSize: "13px" }}
                  className="table _table-condensed table-bordered-content table-hover table-hover-border"
                >
                  <thead className="upper br-sm br-white">
                    <tr className="bg-grey-l">
                      <th>#</th>
                      <th>Metodo</th>
                      <th>Banco</th>
                      <th>Tarjeta</th>
                      <th>Numero </th>
                      <th align="center">Valor</th>
                      <th align="center"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {pagos.map((pago, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{pago.metodo}</td>
                        <td>{pago.banco || "-"}</td>
                        <td>{pago.tarjeta || "-"}</td>
                        <td>{pago.numero || "-"}</td>
                        <td align="center">{pago.valor.toFixed(2)}</td>
                        <td align="center">
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => eliminarPago(index)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      <div align="right">
                        <strong>Total Pagado:</strong>
                      </div>
                    </th>
                    <th style={{ color: "red" }}>
                      <div align="center">
                        <span id="valor_pagado" style={{ color: "green" }}>
                          {calcularTotalPagado().toFixed(2)}
                        </span>
                      </div>
                    </th>
                  </tfoot>

                  <tbody></tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>

        <div align="center" className="mt-4">
          <button
            onclick="insertFactura()"
            className="btn btn-success w-lg waves-effect waves-light btnSaveInfo"
            style={{ width: "24%" }}
            // disabled={!isFacturaButtonEnabled()}
            onClick={() => Registrandofactura()}
          >
            Ingresar Factura
          </button>
        </div>

        <Offcanvas
          style={{
            width: "50%",
            zIndex: 9999,
          }}
          placement="end"
          show={showCliente}
          onHide={handleCloseCliente}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Crear Cliente</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CrearCliente
              cliente={cliente}
              setCliente={setCliente}
              handleCloseCliente={handleCloseCliente}
              setPing={setPing}
              ping={ping}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <ModalNuevoProcedimiento
          show={shoNuevoProcedimiento}
          handleClose={handleCloseProcedimientoNuevo}
          setPing={setPing}
          ping={ping}
          idpaciente={idpaciente}
          setlocalPing={setLocalPing}
        />
        <ModalProcedimientosPendientes
          show={showProcedimientoPendientes}
          handleClose={handleCloseProcedimientoPendientes}
          ping={ping}
          setPing={setPing}
          idpaciente={idpaciente}
          Procedimientos={setProcedimientos}
          setlocalPing={setLocalPing}
        />
      </div>
    </>
  );
}

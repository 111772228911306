
import { Base64 } from 'js-base64';
import { jwtDecode } from "jwt-decode";
import { usuario_token } from './Global';

export function EncryptCualquierDato(data){
    return Base64.encode(data)
}

export function DescryptCualquierDato(data){
    if(data === null || data === undefined){
        return null;
    }
    return Base64.decode(data)
}

export const DecodeJwt = (token) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        return null;
    }
}

export const IsKeyObject =(obj, str)=>{
    return str in obj;
}


export const GetToken = () => {
    let token = localStorage.getItem(usuario_token);
    if(token === null){
        return {
            error: "Token not found",
            message: "No se ha encontrado un token de usuario",
            success: false,
            token: null
        }
    }else{
        let info = DecodeJwt(token);
        if(info.exp < Date.now() / 1000){
            localStorage.removeItem(usuario_token);
            return {
                error: "Token has expired",
                message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
                success: false,
                token: null
            };
        }
        return {
            success: true,
            token: token
        }
    }
}

/**
 * @typedef {Object} DecodedToken
 * @property {number} id - User ID
 * @property {string} full_name - Full name of the user
 * @property {string} user_level - User level or role
 * @property {number} idcompany - Company ID
 * @property {number} idclinica - Clinic ID
 * @property {Array} users_clinicas - Array of user clinics
 * @property {number} iat - Issued At timestamp
 * @property {number} exp - Expiry timestamp
 */
/**
 * Retrieve decoded token from local storage
 * @returns {DecodedToken | null} Decoded token or null if token is not present
 */
export const GetTokenDecoded = () => {
    let token = localStorage.getItem(usuario_token);
    if(token === null){
        return null;
    }else{
        return DecodeJwt(token);
    }
}

export function setDatosUsuario(data) {
    try {
        // const use = EncryptCualquierDato(strings);
        localStorage.setItem(usuario_token,data)
        return true;
    } catch (error) {
        console.log(error);
    }
}

  
export function getDatosUsuario() {
    try {
        const parse = DescryptCualquierDato(localStorage.getItem(usuario_token))
        if(parse === null){
            return null;
        }
        const datos_usuario = JSON.parse(parse)
        return datos_usuario;
    } catch (error) {
        console.log(error);
    }
}
export function removeDatosUsuario() {
    try {
        localStorage.removeItem(usuario_token)
        return true;
    } catch (error) {
        console.log(error);
    }
}

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ListarClinicas } from "../../Service/Serive.clinica";
import { ObtenerCompanies } from "../../Service/Service.compania";
import { CreaDoctor } from "../../Service/Service.doctor";
import { GetEspecialidades } from "../../Service/Service.especialidad";
import { SubirMedia } from "../../Service/ServiceExternal";
import { GetTokenDecoded } from "../../utils/storeUsuario";
import Alert from "../Alert/Alert";
import SelectSearchble from "../SelectSeachble/SelectSearchble";

export default function DoctorCreate({handleClose, setPing, ping, editDoctor}) {

  const [alertclini, setAlertClini] = useState(false)
  const [alertEspec, setAlertEspec] = useState(false)
  const [alertCompany, setAlertCompany] = useState(false)
  const [clinics, setClinics] = useState([]);
  const refDiv = useRef(null)
  const [specialities, setSpecialities] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [send, setSend] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [msj, setMsj] = useState('')
  const [selectedOptionClinica, setSelectedOptionClinica] = useState(null);
  const [selectedOptionCompany, setSelectedOptionCompany] = useState(null);
  const [listCompany, setListCompany] = useState([])
  const [foto, setFoto] = useState(null)
  const [sello, setSello] = useState(null)

const {register, setValue, handleSubmit, formState:{errors}} = useForm();


const [previewImage, setPreviewImage] = useState(null);

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    setPreviewImage(URL.createObjectURL(file));
  }
};
// establecer los valores por default
useEffect(() => {
  (async () => {
    const espec = await GetEspecialidades()
    if(espec){
      setSpecialities(espec.data.map(e => ({value: e.idespecialidad, label: e.especialidad})))
    }

    const clin = await ListarClinicas()
    if(clin){
      setClinics(clin.map(c => ({value: c.idclinica, label: c.nombre})))
    }
    
    const compay = await ObtenerCompanies()
    if(compay){
      setListCompany(compay.map(c => ({value: c.idcompany, label: c.company})))
    }
  })();
}, []);

const handleChange = selectedOption => {
  setSelectedOption(selectedOption);
  setAlertEspec(false)
};
const handleChangeClinica = selectedOptionClinica => {
  setSelectedOptionClinica(selectedOptionClinica);
  setAlertClini(false)
};
const handleChangeCompany = selectedOptionCompany => {
  setSelectedOptionCompany(selectedOptionCompany);
  setAlertCompany(false)
};

  useEffect(() => {
    if (editDoctor != null) {
      setFoto(editDoctor.foto)
      setSello(editDoctor.sello)
      setValue('iddoctor', editDoctor.iddoctor)
      setValue('prefijo', editDoctor.prefijo)
      setValue('nombre', editDoctor.nombre)
      setValue('cedula', editDoctor.cedula)
      setValue('telefono', editDoctor.telefono)
      setValue('celular', editDoctor.celular)
      setValue('email', editDoctor.email)
      setValue('titulo', editDoctor.titulo)
      setValue('idespecialidad', editDoctor.idespecialidad)
      setValue('genero', editDoctor.genero)
      setValue('idclinica', editDoctor.idclinica)
      setValue('idcompany', editDoctor.idcompany)
      // setValue('foto', editDoctor.foto)
      setValue('intervalo_tiempo', editDoctor.intervalo_tiempo)
      setValue('horario_inicio', editDoctor.horario_inicio)
      setValue('horario_fin', editDoctor.horario_fin)
      setValue('activo', editDoctor.activo)
      setValue('vacaciones', editDoctor.vacaciones)
      setValue('sello', editDoctor.sello)
      setValue('fecha_nac', editDoctor.fecha_nac)
      setValue('calcolor', editDoctor.calcolor)
      setValue('registro', editDoctor.registro)
      setValue('tipoID', editDoctor.tipoID)
      if(editDoctor.doctors_dias_laborables.length > 0){
        setValue('lunes', editDoctor.doctors_dias_laborables[0].lunes)
        setValue('martes', editDoctor.doctors_dias_laborables[0].martes)
        setValue('miercoles', editDoctor.doctors_dias_laborables[0].miercoles)
        setValue('jueves', editDoctor.doctors_dias_laborables[0].jueves)
        setValue('viernes', editDoctor.doctors_dias_laborables[0].viernes)
        setValue('sabado', editDoctor.doctors_dias_laborables[0].sabado)
        setValue('domingo', editDoctor.doctors_dias_laborables[0].domingo)
      }
      setSelectedOption({value: editDoctor.especialidades.idespecialidad, label: editDoctor.especialidades.especialidad})
      setSelectedOptionClinica({value: editDoctor.clinica.idclinica, label: editDoctor.clinica.nombre})
      setSelectedOptionCompany({value: editDoctor.compania.idcompany, label: editDoctor.compania.company})
    }else{
      setValue('lunes', true)
      setValue('martes', true)
      setValue('miercoles', true)
      setValue('jueves', true)
      setValue('viernes', true)
      setValue('sabado', false)
      setValue('domingo', false)
      setValue('vacaciones', false)
      setValue('activo', true)
      setValue('intervalo_tiempo', 15)
    }
  }, []);

  const onSubmit = handleSubmit(async(data) => {
    if(!selectedOption){
      setAlertClini(true)
      return;
    }
    if(!selectedOptionClinica){
      setAlertEspec(true)
      return;
    }

    if(selectedOptionCompany){
      data['idcompany'] = selectedOptionCompany.value
    }else{
      data['idcompany'] = GetTokenDecoded().idcompany
    }

    if(editDoctor){
      data['iddoctor'] = editDoctor.iddoctor
    }
    setSend(true)

    if(data.foto && data.foto.length > 0 && data.foto[0]){
      const url = await SubirMedia(data.foto[0], null, null, 0, 'foto_doctor');
      data.foto = url;
    }else{
      data.foto = foto
    }

    if(data.sello && data.sello.length > 0 && data.sello[0]){
      const url = await SubirMedia(data.sello[0], null, null, 0, 'sello_doctor');
      data.sello = url;
    }else{
      data.sello = sello
    }
    data['idespecialidad'] = selectedOption.value
    data['idclinica'] = selectedOptionClinica.value
    
    data['dias_laborales'] = {
      lunes: data.lunes,
      martes: data.martes,
      miercoles: data.miercoles,
      jueves: data.jueves,
      viernes: data.viernes,
      sabado: data.sabado,
      domingo: data.domingo
    }
   

    // foto, sello
    console.log('foto: ', foto)
    console.log('sello: ', sello)
    console.log(data)

    const response = await CreaDoctor(data)
    if(response){
      setAlertType('success')
    }else{
      setAlertType('error')
    }
    setSend(false)
    refDiv.current.scrollIntoView({behavior: 'smooth'})
    setTimeout(() => {
      setAlertType('')
      setPing(!ping)
      handleClose()
    }, 800);
  })

  return (
    <section>
      <Alert
          tipo_alert={msj || alertType}
          type={msj || alertType}
        />
      <div className="row">
        <div
          ref={refDiv}
        />
        <div className="col-12">
          <div className="card">
            <div className="card-body">

              <form id="form-create-doctor" onSubmit={onSubmit}>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Especialidad *</label>
                  <div className="col-sm-9">
                    <SelectSearchble
                      listCompany={specialities} 
                      handleChange={handleChange} 
                      selectedOption={selectedOption} 
                      placeholder="Seleccione especialidad"
                      isMulti={false}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={alertEspec ? 'select' : ''}
                    />
                  </div>
                </div>

                {
                  GetTokenDecoded().idcompany === 0 ? 
                  (
                    <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Compania *</label>
                    <div className="col-sm-9">
                      <SelectSearchble
                        listCompany={listCompany} 
                        handleChange={handleChangeCompany} 
                        selectedOption={selectedOptionCompany} 
                        placeholder="Seleccione compania"
                        isMulti={false}
                      />
                      <Alert
                        tipo_alert="danger"
                        type={alertCompany ? 'select' : ''}
                      />
                    </div>
                  </div>
                  )
                   : null
                }

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Clinica *</label>
                  <div className="col-sm-9">
                    <SelectSearchble
                      listCompany={clinics} 
                      handleChange={handleChangeClinica} 
                      selectedOption={selectedOptionClinica} 
                      placeholder="Seleccione clinica"
                      isMulti={false}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={alertclini ? 'select' : ''}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Prefijo*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" id="Prefijo" name="Prefijo"
                      {...register('prefijo', {required: true,
                        maxLength: 5,
                        // pattern: /^[A-Z0-9]*$/i
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.prefijo?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombre*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('nombre', {required: true,
                        maxLength: 50,
                        minLength: 5
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.nombre?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Cedula*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('cedula', {required: true,
                        maxLength: 13,
                        pattern: /^[0-9]*$/i,
                        minLength: 10
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.cedula?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Telefono</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('telefono', {required: false,
                        maxLength: 10,
                        pattern: /^[0-9]*$/i,
                        minLength: 7
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.telefono?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Celular*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('celular', {required: true,
                        maxLength: 12,
                        pattern: /^[0-9]*$/i,
                        minLength: 10
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.celular?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Email*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('email', {required: true,
                        maxLength: 50,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        minLength: 8
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.email?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Titulo*</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('titulo', {required: true,
                        maxLength: 50,
                        minLength: 5
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.titulo?.type}
                    />  
                  </div>
                </div>


                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Genero*</label>
                  {/* que sea un select */}
                  <div className="col-sm-9">
                    <select className="form-select" 
                      {...register('genero', {required: true})}
                    >
                      {
                        ['Masculino', 'Femenino'].map((g, index) => (
                          <option value={g} key={index}
                            selected={editDoctor && editDoctor.genero === g}
                          >{g}</option>
                        ))
                      }
                    </select>
                    <Alert
                      tipo_alert="danger"
                      type={errors.genero?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Intervalo de atencion</label>
                  <div className="col-sm-9">
                    <input type="number" className="form-control" 
                      {...register('intervalo_tiempo', {required: false,
                        maxLength: 60,
                        pattern: /^[0-9]*$/i,
                        minLength: 1,
                        defaultValue: 15,
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Horario*</label>
                  <div className="col-sm-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Inicio</label>
                    <input type="time" className="form-control" 
                      {...register('horario_inicio', {required: true})}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.horario_inicio?.type}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Fin</label>
                    <input type="time" className="form-control" 
                      {...register('horario_fin', {required: true})}
                    />
                    <Alert 
                      tipo_alert="danger"
                      type={errors.horario_fin?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Foto</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control" name="foto" id="foto"
                      {...register('foto', {required: false,
                        pattern: /\.(jpe?g|png)$/i
                      })}
                      onChange={handleFileChange}
                    />
                    
                    <Alert
                      tipo_alert="danger"
                      type={errors.foto?.type}
                    />
                    {/*
                       Si se esta editando y existe una foto hacer un preview de la foto
                    */}
                    {/* Si se está editando y existe una foto, mostrar una vista previa */}
                    {editDoctor && editDoctor.foto ? (
                      <div className="d-flex justify-content-start align-items-center">
                        <div
                          className="me-3"
                          style={{ width: '100px', height: '100px' }}
                        >
                          <span>Imagen actual</span>
                          <img
                            src={editDoctor.foto}
                            alt="foto actual"
                            className="img-fluid mt-2"
                            style={{ width: '100px', height: '100px' }}
                          />
                        </div>
                        {previewImage && (
                          <div className="ms-3 "
                            style={{ width: '100px', height: '100px' }}
                          >
                            <span>Nueva imagen</span>
                            <img
                              src={previewImage}
                              alt="nueva foto"
                              className="img-fluid mt-2"
                              style={{ width: '100px', height: '100px' }}
                            />
                          </div>
                        )}
                      </div>
                    ) : previewImage ? (
                      <div className="w-16"
                        style={{ width: '100px', height: '100px' }}
                      >
                        <span>Nueva imagen</span>
                        <img
                          src={previewImage}
                          alt="nueva foto"
                          className="img-fluid mt-2"
                          style={{ width: '100px', height: '100px' }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Estado</label>
                  <div className="col-sm-9">
                    <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                      <input type="checkbox" className="form-check-input" 
                        {...register('activo', {required: false,
                          defaultValue: true
                        })}
                      />
                    </div>
                    <Alert
                      tipo_alert="danger"
                      type={errors.activo?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Vacaciones</label>
                  <div className="col-sm-9">
                    <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                      <input type="checkbox" className="form-check-input" 
                        {...register('vacaciones', {required: false,
                          defaultValue: false,
                        })}
                      />
                    </div>  
                    <Alert
                      tipo_alert="danger"
                      type={errors.vacaciones?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Sello</label>
                  <div className="col-sm-9">
                    <input type="file" className="form-control"
                      {...register('sello', {required: false,
                        // imagen con formato png, jpg, jpeg
                        pattern: /\.(jpe?g|png)$/i
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.sello?.type}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Fecha nacimiento</label>
                  <div className="col-sm-9">
                    <input type="date" className="form-control" 
                      {...register('fecha_nac', {required: false})}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Cal Color</label>
                  <div className="col-sm-2">
                    <input type="color" className="form-control" 
                      {...register('calcolor', {required: false,
                        defaultValue: '#ffffff'
                      })}
                    />
                  </div>
                </div>

                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Registro</label>
                  <div className="col-sm-9">
                    <input type="text" className="form-control" 
                      {...register('registro', {required: false,
                        maxLength: 160,
                        pattern: /^[A-Z0-9-]*$/i,
                        defaultValue: ''
                      })}
                    />
                    <Alert
                      tipo_alert="danger"
                      type={errors.registro?.type}
                    />

                  </div>
                </div>
                
                <div className="row mb-4">
                  <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Dias laborales</label>
                  <div className="col-sm-9">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input" 
                            {...register('lunes', {required: false,
                              defaultValue: true
                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Lunes</label>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input" 
                            {...register('martes', {required: false,
                              defaultValue: true,
                              // validar que sea un valor booleano y se pueda cambiar el valor
                              validate: (value)=>{
                                if(typeof value === 'boolean'){
                                  return true
                                }else{
                                  return false
                                }
                              }
                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Marte</label>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input" 
                            {...register('miercoles', {required: false,
                              defaultValue: true
                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Miercoles</label>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input"
                            {...register('jueves', {required: false,
                              defaultValue: true
                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Jueves</label>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input" 
                            {...register('viernes', {required: false,
                              defaultValue: true
                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Viernes</label>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input"

                            {...register('sabado', {required: false,
                              defaultValue: false
                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Sabado</label>
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <input type="checkbox" className="form-check-input"
                            {...register('domingo', {required: false,
                              defaultValue: false,

                            })}
                          />
                            <label className="form-check-label" for="customSwitchsizemd">Domingo</label>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div className="text-center">
                  <button onclick="openModalDoctores()" className="btn btn-danger m-1">Cancelar</button>
                  <button type="submit" className="btn btn-primary ">Guardar</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div> 


    </section>
  );
}

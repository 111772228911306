

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ControllerProcedimientosPrecios, ControllerVincularProcedimiento } from '../../../Service/Service.porcedimientos';


export default function ModalNuevoProcedimiento({show, handleClose, ping, setPing, idpaciente}) {
    const [procedimientos, setProcedimientos] = useState([]);
    const [nuevoProcedimiento, setNuevoProcedimiento] = useState({
      idpaciente: idpaciente,
      idExamenTipo: "",
      precio: "",
      comentarios: "",
    });
    const [SelectOption, setSelectOption] = useState(null);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNuevoProcedimiento({
        ...nuevoProcedimiento,
        [name]: value,
      });
    };

    const handleChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectOption(selectedOption);
        setNuevoProcedimiento({
            ...nuevoProcedimiento,
            idExamenTipo: selectedOption.value,
            precio: selectedOption.precio,
        });
    }
  
    const guardarNuevoProcedimiento = async () => {
      try {
        setNuevoProcedimiento({
          ...nuevoProcedimiento,
          idpaciente: parseInt(idpaciente),
        });
        const result = await ControllerVincularProcedimiento(nuevoProcedimiento);
        if(result.success) {
          Swal.fire({
            icon: "success",
            title: "Éxito",
            text: "Procedimiento guardado correctamente",
          });
          // Limpiar el formulario
          setNuevoProcedimiento({
            idExamenTipo: "",
            precio: "",
            comentarios: "",
            idpaciente: idpaciente,
          });
          // Cerrar el modal
          handleClose();
  
        } else {
          // La API devolvió un error
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
            result.message ||
              "Hubo un error al guardar el procedimiento",
          });
        }
      } catch (error) {
        console.error("Error al guardar el procedimiento:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error.response?.data?.message ||
            "Hubo un error al guardar el procedimiento",
        });
      }
    };
  
    useEffect(() => {
      (async () => {
        if (show) {
          console.log("Obteniendo procedimientos");
          const result = await ControllerProcedimientosPrecios();
          if (result.success) {
            setProcedimientos(result.data.map(p => ({
                value: p.ExamenTipo.IdExamenTipo, 
                label: p.ExamenTipo.codigo +" - "+ p.ExamenTipo.descripcion,
                precio: p.precio
            })));
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text:
                result.message ||
                "Hubo un error al obtener los procedimientos",
            });
          }
        }
      })()
    }, [show])
    // useEffect(() => {
    //   (async () => {
    //     if (show) {
    //       console.log("Obteniendo procedimientos");
    //       const result = await ControllerObtenerProcedimientos(idpaciente);
    //       if (result.success) {
    //         setProcedimientos(result.data);
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Error",
    //           text:
    //             result.message ||
    //             "Hubo un error al obtener los procedimientos",
    //         });
    //       }
    //     }
    //   })()
    // }, [show])
  
    return (
      <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size='lg'
      >
            <Modal.Header closeButton>
              <Modal.Title>Agregar Procedimiento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="i_c_procedimientos" className="text-uppercase">
                    Procedimiento
                  </label>
                  <Select
                    className="form-control"
                    id="i_c_procedimientos"
                    name="procedimiento"
                    value={SelectOption}
                    onChange={handleChange}
                    options={procedimientos}
                  />
                </div>
  
                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="i_c_proc_precio" className="text-uppercase">
                    Precio
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="i_c_proc_precio"
                    name="precio"
                    value={nuevoProcedimiento.precio}
                    onChange={handleInputChange}
                  />
                </div>
  
                <div className="form-group col-md-12 mb-3">
                  <label htmlFor="i_c_coment_proce" className="text-uppercase">
                    Comentarios
                  </label>
                  <textarea
                    className="form-control"
                    rows="2"
                    id="i_c_coment_proce"
                    name="comentarios"
                    value={nuevoProcedimiento.comentarios}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
              >
                Cerrar
              </Button>
              <Button variant="primary" onClick={guardarNuevoProcedimiento}>
                Guardar Procedimiento
              </Button>
            </Modal.Footer>
      </Modal>
    )
}



import React, { useEffect, useState } from 'react';
import { Modal, Offcanvas } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { ActualizarProcedimientoPrecio, CrearProcedimientoPrecio, EliminarProcedimientoPrecio, ListarProcedimientoConPrecios, ListCategoriaExamenProcedimientos } from '../../../Service/Service.examen';
import Alert from '../../Alert/Alert';
import SelectSearchble from '../../SelectSeachble/SelectSearchble';
import Speener from '../../Speener/Speener';

export default function ProcedimientoPrecio() {

    const [listarProcedimeintos, setListarProcedimientos] = useState([]);
    const [procedimientos, setProcedimientos] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);

    const [loading, setLoading] = useState(false);
    const [tipoAlert, setTipoAlert] = useState('')
    const [alertMensaje, setAlertMensaje] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showPprecio, setShowPprecio] = useState(false);
    const handlePrecioClose = () => setShowPprecio(!showPprecio);

    const [nuevoProcedimiento, setNuevoProcedimiento] = useState({
        editar: false,
        idexamen_tipo: 0,
        precio: 0,
        idcompany: 0,
        descuento: 0
    })


    const editarPrecio = (procedimiento) => {
        setNuevoProcedimiento({
            editar: true,
            idexamen_tipo: procedimiento.idExamenTipo,
            precio: procedimiento.precio,
            idcompany: procedimiento.idcompany,
            descuento: procedimiento.descuento
        })
        setSelectedOption({
            value: procedimiento.ExamenTipo.IdExamenTipo,
            label: procedimiento.ExamenTipo.codigo+ " - " +procedimiento.ExamenTipo.descripcion,
        })

        setShowPprecio(true)
    }

    const ConfirmarActualizacion = async() => {
        const response = await ActualizarProcedimientoPrecio(nuevoProcedimiento);
        if(response.success){
            Swal.fire({
                title: 'Procedimiento actualizado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            })
            setNuevoProcedimiento({
                editar: false,
                idexamen_tipo: 0,
                precio: 0,
                idcompany: 0,
                descuento: 0
            })
            const response = await ListarProcedimientoConPrecios();
            if(response.success){
                setListarProcedimientos(response.data)
            }
            handlePrecioClose()
            setSelectedOption(null)
        }else{
            Swal.fire({
                title: 'Error al actualizar el procedimiento',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    useEffect(() => {
        (async()=>{
            const response = await ListarProcedimientoConPrecios();
            if(response.success){
                setListarProcedimientos(response.data)
            }  
            const procedimientos = await ListCategoriaExamenProcedimientos();
            if(procedimientos.success){
                setProcedimientos(procedimientos.data.map(procedimiento => ({
                    value: procedimiento.IdExamenTipo,
                    label: procedimiento.codigo+ " - " +procedimiento.descripcion,
                })))
            }
        })()
    }, [])

    const handleChange = (selectedOption) => {
        console.log(selectedOption)
        setSelectedOption(selectedOption);
        setNuevoProcedimiento({
            ...nuevoProcedimiento,
            idexamen_tipo: selectedOption.value
        })
    };

    const handleBuscar = (value) => {
        if(String(value).length > 3){
            setLoading(true)
            ListCategoriaExamenProcedimientos(value).then(response => {
                if(response.success){
                    setProcedimientos(response.data.map(procedimiento => ({
                        value: procedimiento.IdExamenTipo,
                        label: procedimiento.descripcion,
                    })))
                }
                setLoading(false)
            }).catch(error => {
                console.log(error)
                setLoading(false)
            })
        }
    }
    

    const CreatePrecio = async() => {
        if(nuevoProcedimiento.idexamen_tipo === 0){
            setTipoAlert('danger')
            setAlertMensaje('Seleccione un procedimiento')
            setTimeout(() => {
                setAlertMensaje('')
            }, 3000);
            return;
        }
        if(nuevoProcedimiento.precio === 0){
            setTipoAlert('danger')
            setAlertMensaje('Ingrese un precio')
            setTimeout(() => {
                setAlertMensaje('')
            }, 3000);
            return;
        }

        const response = await CrearProcedimientoPrecio(nuevoProcedimiento);
        if(response.success){
            Swal.fire({
                title: 'Procedimiento creado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            })
            setNuevoProcedimiento({
                editar: false,
                idexamen_tipo: 0,
                precio: 0,
                idcompany: 0,
                descuento: 0
            })
            const response = await ListarProcedimientoConPrecios();
            if(response.success){
                setListarProcedimientos(response.data)
            }
            handlePrecioClose()
            setSelectedOption(null)
        }else{
            Swal.fire({
                title: 'Error al crear el procedimiento',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }
    }

    const deleteProcedimiento = async (idprocedimiento_company_precios) => {
        Swal.fire({
            title: '¿Estas seguro de eliminar el procedimiento?',
            showDenyButton: true,
            confirmButtonText: `Si`,
            denyButtonText: `No`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await EliminarProcedimientoPrecio(idprocedimiento_company_precios);
                if(response.success){
                    setTipoAlert('success')
                    setAlertMensaje('Procedimiento eliminado correctamente')
                    setTimeout(() => {
                        setAlertMensaje('')
                    }, 3000);
                    const response = await ListarProcedimientoConPrecios();
                    if(response.success){
                        setListarProcedimientos(response.data)
                    }  
                }else{
                    setTipoAlert('danger')
                    setAlertMensaje('Error al eliminar el procedimiento')
                    setTimeout(() => {
                        setAlertMensaje('')
                    }, 3000);
                }
            }
        })
    }

  return (
    <section>
      <Alert
        tipo_alert={tipoAlert}
        msj={alertMensaje}
      />
      <div className="row">
        <div className="col-12">

          <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" className="btn btn-light waves-effect waves-light" onClick={handlePrecioClose}>
                <i className="bx bx-plus me-1"></i> Nuevo Precio Procedimiento </button>
            <Speener loading={loading} />
          </div>

          <table id="tb_clinicas" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Codigo</th>
                <th>Descripcion</th>
                <th>Tipo</th>
                <th>Precio</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                listarProcedimeintos.map((seguro, index) => (
                  <tr key={seguro.idprocedimiento_company_precios}>
                    <td align="center">{index + 1}</td>
                    <td>{seguro.ExamenTipo.codigo}</td>
                    <td>{seguro.ExamenTipo.descripcion}</td>
                    <td>{seguro.ExamenTipo.tipo}</td>
                    <td>{seguro.precio}</td>
                    <td align="left">
                      <div className="btn-group"><button type="button"
                        className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#;" onClick={()=>editarPrecio(seguro)}>Editar</a>
                          <a className="dropdown-item" href="#;" onClick={()=>deleteProcedimiento(seguro.idprocedimiento_company_precios)}>Eliminar</a>
                          {/* <div className="dropdown-divider"></div> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal tabla de planes  */}
      <div className="col-12">
      <Modal show={showPprecio} onHide={handlePrecioClose}>
        <Modal.Header >
          <Modal.Title>Precio de procedimiento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Procedimiento</label>
                    <SelectSearchble
                        listCompany={procedimientos}
                        handleChange={handleChange} 
                        selectedOption={selectedOption} 
                        placeholder="Seleccione procedimiento"
                        isMulti={false}
                        onInputChange={handleBuscar}
                        isDisabled={nuevoProcedimiento.editar}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Precio</label>
                    <input type="number" className="form-control" value={nuevoProcedimiento.precio} onChange={(e)=>setNuevoProcedimiento({...nuevoProcedimiento, precio: e.target.value})} />
                </div>

                <div className="mb-3">
                    <label className="form-label">Descuento</label>
                    <input type="number" className="form-control" value={nuevoProcedimiento.descuento} onChange={(e)=>setNuevoProcedimiento({...nuevoProcedimiento, descuento: e.target.value})} />
                </div>

                <div className="mb-3 text-end">
                    <button className="btn btn-light m-1" onClick={handlePrecioClose}>Cancelar</button>
                    <button className="btn btn-primary m-1" onClick={
                        !nuevoProcedimiento.editar ? CreatePrecio : ConfirmarActualizacion
                    }>
                        {
                            !nuevoProcedimiento.editar ? 'Guardar' : 'Actualizar'
                        }
                    </button>
                </div>  

            </div>
          </div>

        </Modal.Body>
      </Modal>
    </div>

      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Seguro
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}

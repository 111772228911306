import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import { ListCategoriaExamen } from "../../../Service/Service.categoriaExamen";
import TableExamen from "../../Table/TableExamen";

export default function Examenes({historial, OpenSwichtFormulario,ingresoConsulta, ver_citas}) {

    const [examenes, setExamenes] = useState([]);

    useEffect(() => {
        (async () => {
            const forms = await ListCategoriaExamen();
            if (forms.data) {
            setExamenes(forms.data);
            }
        })();
    }, [historial]);

  return (
    <div
      className="tab-pane fade"
      id="v-pills-examenes"
      role="tabpanel"
      aria-labelledby="v-pills-examenes-tab"
    >
      <p>
        <div className="text-star card-subtitle text-muted">
          INGRESO DE EXAMENES
        </div>
      </p>

      <div className="row">
        {examenes.map((f, index) => (
          <div className="col-sm-4 col-lg-2" key={index}>
            <div
              className="card cardHover"
              style={{
                borderRadius: "5px",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                height: "100%",
                maxHeight: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                className="card-body"
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if(ver_citas){
                    OpenSwichtFormulario(f.id_categoria_examen);
                  }else{
                    Swal.fire({
                      icon: "warning",
                      title: "Oops...",
                      text: "La cita ya fue atendida o la fecha de la cita ya paso",
                    });
                  }
                }}
              >
                <h5 className="card-title">{f.Descripcion}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Tabs
        defaultActiveKey="laboratorio"
        id="uncontrolled-tab-example"
        className="mb-3 mt-4"
      >
        <Tab eventKey="laboratorio" title="Laboratorio">
          <TableExamen
            examenes={ingresoConsulta.examen_cita}
            type={"laboratorio"}
          />
        </Tab>

        <Tab eventKey="imagen" title="Imagenes">
          <TableExamen
            examenes={ingresoConsulta.examen_imagen_orden}
            type={"imagenes"}
          />
        </Tab>
        
      </Tabs>
    </div>
  );
}

import { BmHttp } from "../utils/Global";


export const ControllerListarSegurosClinica = async (idclinica) => {
    try {
        const {data} = await BmHttp().get("listar_seguros?idclinica="+idclinica);
        return data.data;
    } catch (error) {
        console.error(error);
    }
}

export const ControllerCrearSeguro = async (seguro) => {
    try {
        const {data} = await BmHttp().post("create_seguro", seguro);
        console.log(data);
        return data.success
    } catch (error) {
        console.error(error);
    }
}

export const ControllerEditarSeguro = async (seguro) => {
    try {
        const {data} = await BmHttp().put("update_seguro", seguro);
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const ControllerEliminarSeguro = async (id) => {
    try {
        const {data} = await BmHttp().delete("delete_seguro?idseguro="+id);
        return data;
    } catch (error) {
        console.error(error);
    }
}

// Plan Seguros
export const ControllerListarPlanesSeguro = async (idseguro) => {
    try {
        const {data} = await BmHttp().get("listar_plan_seguro?idseguro="+idseguro);
        return data.data;
    } catch (error) {
        console.error(error);
    }
}

export const ControllerCrearPlanSeguro = async (plan) => {
    try {
        const {data} = await BmHttp().post("create_plan_seguro", plan);
        return data.success
    } catch (error) {
        console.error(error);
    }
}

export const ControllerUpdatePlanSeguro = async (plan) => {
    try {
        const {data} = await BmHttp().put("update_plan_seguro", plan);
        return data.success
    } catch (error) {
        console.error(error);
    }
}

export const ControllerDeletePlanSeguro = async (id) => {
    try {
        const {data} = await BmHttp().delete("delete_plan_seguro?idplanseguro="+id);
        return data.success
    } catch (error) {
        console.error(error);
    }
}


import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Swal from 'sweetalert2';
import { ControllerDeletePlanSeguro, ControllerEliminarSeguro, ControllerListarPlanesSeguro, ControllerListarSegurosClinica } from '../../../Service/Services.seguros';
import { GetTokenDecoded } from '../../../utils/storeUsuario';
import Alert from '../../Alert/Alert';
import Speener from '../../Speener/Speener';
import CreateSeguro from './CreateSeguro';
import ModalCreaPlan from './ModalCreaPlan';


export default function Seguros({tabs}) {
    const [show, setShow] = useState(false);
    const [showPlan, setShowPlan] = useState(false);
    const [showCreaPlan, setShowCreaPlan] = useState(false);

    const [tipoAlert, setTipoAlert] = useState('')
    const [alertMensaje, setAlertMensaje] = useState('')
    const [editSeguro, setEditSeguro] = useState(null)

    const [listSeguro, setListSeguro] = useState([])
    const [listPlan, setListPlan] = useState([])

    const [loading, setLoading] = useState(false)
    const [ping, setPing] = useState(false)

    const [idseguro, setIdseguro] = useState(null)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePlanClose = () => setShowPlan(false);
    const handlecreaPlanClose = (seguro) => {
      setEditSeguro(seguro)
      setShowCreaPlan(!showCreaPlan)
    };

    useEffect(() => {
        (async()=>{
          setLoading(true)
          const response = await ControllerListarSegurosClinica(GetTokenDecoded().idclinica)
          if(response){
            setListSeguro(response)
            setLoading(false)
          }
        })()
    }, [ping, tabs.clinicas])

    const editarSeguro= (segruo) => {
      setEditSeguro(segruo)
      handleShow()
    }
    
    const DeleteSeguro = async(idseguro) => {
        Swal.fire({
            title: 'Estas seguro de eliminar esta clinica?',
            text: "No podras revertir esta accion!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar!'
          }).then(async(result) => {
            if (result.isConfirmed) {
              const response = await ControllerEliminarSeguro(idseguro)
              if(response){
                setPing(!ping)
                setTipoAlert('success')
                setAlertMensaje('Clinica eliminda con exito')
              }else{
                setTipoAlert('danger')
                setAlertMensaje('Ocurrio un error en la operacion por favor intente nuevamente')
              }
              setTimeout(()=>{
                setTipoAlert('')
                setAlertMensaje('')
              },1500)
            }
          })
    }

    const EliminarPlan = async(id) => {
      Swal.fire({
          title: 'Estas seguro de eliminar este plan?',
          text: 'No podras revertir esta accion!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Eliminar!'
        }).then(async(result) => {
          if (result.isConfirmed) {
              console.log(id);
              const response = await ControllerDeletePlanSeguro(id)
              if(response){
                  Swal.fire(
                      'Eliminado!',
                      'El plan ha sido eliminado.',
                      'success'
                  )
                  setPing(!ping)
              }else{
                  Swal.fire(
                      'Error!',
                      'Ha ocurrido un error.',
                      'error'
                  )
              }
          }
        })
  }

    const openPlanSeguros = async (idseguro) => {
      if(idseguro){
        setIdseguro(idseguro)
        const response = await ControllerListarPlanesSeguro(idseguro)
        if(response){
          setListPlan(response)
        }
      }else{
        setListPlan([])
      }
      setShowPlan(!showPlan)
    }

    useEffect(() => {
      (async()=>{
        if(idseguro){
          setIdseguro(idseguro)
          const response = await ControllerListarPlanesSeguro(idseguro)
          if(response){
            setListPlan(response)
          }
        }
      })()
    }, [ping])

  return (
    <section>
      <Alert
        tipo_alert={tipoAlert}
        msj={alertMensaje}
      />
      <div className="row">
        <div className="col-12">

          <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
            <button type="button" className="btn btn-light waves-effect waves-light" onClick={handleShow}>
                <i className="bx bx-plus me-1"></i> Crear Seguro</button>
            <Speener loading={loading} />
          </div>

          <table id="tb_clinicas" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
            <thead>
              <tr>
                <th width="15">#</th>
                <th>Nombre</th>
                <th>Ruc</th>
                <th>Razon Social</th>
                <th>Representante</th>
                <th>Direccion</th>
                <th>Telefono</th>
                <th>Correo</th>
                <th>Medico Auditor</th>
                <th>Fecha Corte</th>
                <th width="50">Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                listSeguro.map((seguro, index) => (
                  <tr key={seguro.idclinica}>
                    <td align="center">{index + 1}</td>
                    <td>{seguro.nombre}</td>
                    <td>{seguro.ruc}</td>
                    <td>{seguro.razon_social}</td>
                    <td>{seguro.responsable}</td>
                    <td>{seguro.direccion}</td>
                    <td>{seguro.telefono}</td>
                    <td>{seguro.correo_electronico}</td>
                    <td>{seguro.medico_auditor}</td>
                    <td>
                      {/* from-swich */}
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={seguro.estado} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                      </div>
                    </td>
                    <td align="left">
                      <div className="btn-group"><button type="button"
                        className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i></button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" href="#;" onClick={()=>editarSeguro(seguro)}>Editar</a>
                          <a className="dropdown-item" href="#;" onClick={()=>openPlanSeguros(seguro.idseguro)}>Agr. Plan</a>
                          <a className="dropdown-item" href="#;" onClick={()=>DeleteSeguro(seguro.idseguro)}>Eliminar</a>
                          {/* <div className="dropdown-divider"></div> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal tabla de planes  */}
      <div className="col-12">
      <Modal show={showPlan} onHide={handlePlanClose}>
        <Modal.Header >
          <Modal.Title>Plan de Seguro
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between ">
                <button type="button" className="btn btn-light waves-effect waves-light" onClick={()=>handlecreaPlanClose(null)}>
                  <i className="bx bx-plus me-1"></i> Crear Plan</button>
              </div>
              <table id="tb_clinicas" className="table dt-responsive table-striped nowrap w-100" style={{ fontSize: '14px' }}>
                <thead>
                  <tr>
                    <th>Seguro</th>
                    <th>plan</th>
                    <th>copago</th>
                    <th width="50">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    listPlan.map((seguro, index) => (
                      <tr key={seguro.idseguro}>
                        <td>{seguro.plan}</td>
                        <td>Plan Basico</td>
                        <td>100%</td>
                        <td align="left">
                          <div className="btn-group">
                            <button type="button"
                              className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" data-bs-toggle="dropdown"
                              aria-expanded="false"><i className="bx bx-dots-horizontal-rounded"></i>
                            </button>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#;" onClick={()=>handlecreaPlanClose(seguro)} >Editar</a>
                              <a className="dropdown-item" href="#;" onClick={()=>EliminarPlan(seguro.idplanseguro)} >Eliminar</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>

        </Modal.Body>

      </Modal>
    </div>
      <ModalCreaPlan
        showCreaPlan={showCreaPlan}
        handlecreaPlanClose={handlecreaPlanClose}
        editarPlan={editSeguro}
        idseguro={idseguro}
        setPing={setPing}
        ping={ping}
      />


      <Offcanvas style={{ width: '50%' }} placement="end" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Creación Seguro
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateSeguro
            setPing={setPing}
            ping={ping}
            handleClose={handleClose}
            show={show}
            editSeguro={editSeguro}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  )
}

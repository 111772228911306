

import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ListarDoctorClinica } from '../../../Service/Service.doctor'
import { ControllerCrearSeguro } from '../../../Service/Services.seguros'
import { GetTokenDecoded } from '../../../utils/storeUsuario'
import Alert from '../../Alert/Alert'
import SelectSearchble from '../../SelectSeachble/SelectSearchble'

export default function CreateSeguro({setPing, ping, show, handleClose, editSeguro}) {

    const refDiv = useRef(null)
    const [doctores, setDoctores] = useState([])
    const [send, setSend] = useState(false)
    const [alertType, setAlertType] = useState('')
    const [msj, setMsj] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [seguro, setSeguro] = useState({
      idclinica: 0,
      idtiposeguro: 0,
      nombre: '',
      ruc: '',
      razon_social: '',
      direccion: '',
      responsable: '',
      telefono: '',
      correo_electronico: '',
      estado: '',
      medico_auditor: '',
      fecha_corte: false,

    })
  
  
    const {register, setValue, handleSubmit, formState:{errors}} = useForm();
  

    const handleChange = selectedOption => {
      setSelectedOption(selectedOption);
    };

    useEffect(() => {
        (async()=>{
            const doct = await ListarDoctorClinica(GetTokenDecoded().idclinica)
            console.log(doct)
            setDoctores(doct.data.map((doc) => {
                return {
                    value: doc.iddoctor,
                    label: doc.nombre
                }
            }))
        })()
    }, [])
  
    useEffect(() => {
      if(editSeguro){
        setSeguro(editSeguro)
        setValue('nombre', editSeguro.nombre)
        setValue('ruc', editSeguro.ruc)
        setValue('razon_social', editSeguro.razon_social)
        setValue('direccion', editSeguro.direccion)
        setValue('responsable', editSeguro.responsable)
        setValue('telefono', editSeguro.telefono)
        setValue('correo_electronico', editSeguro.correo_electronico)
        setValue('estado', editSeguro.estado)
        setValue('medico_auditor', editSeguro.medico_auditor)
        setValue('fecha_corte', editSeguro.fecha_corte)
      }
    }, [show])
  
  
  
    const onSubmit = handleSubmit(async(data) => {
      console.log("handleSubmit: ", data)
      console.log("editSeguro: ", editSeguro)
      if(JSON.stringify(data) === JSON.stringify(editSeguro)){
        setMsj('No hay cambios para guardar')
        refDiv.current.scrollIntoView({behavior: 'smooth'})
        setTimeout(() => {
          setAlertType('')
        }, 3000);
        return
      }
      setSend(true)

      if(selectedOption){
        data['medico_auditor'] = selectedOption.value
      }

      if(editSeguro){
        data['idseguro'] = editSeguro.idseguro
        data['idclinica'] = editSeguro.idclinica
      }else{
        data['idseguro'] = 0
        data['idclinica'] = GetTokenDecoded().idclinica
      }

      const response = await ControllerCrearSeguro(data)
      if(response){
        setAlertType('success')
      }else{
        setAlertType('error')
      }
      setSend(false)
      refDiv.current.scrollIntoView({behavior: 'smooth'})
      setTimeout(() => {
        setAlertType('')
        setPing(!ping)
        handleClose()
      }, 3000);
    })

  return (
    <section>
    <Alert
      tipo_alert={msj || alertType}
      type={msj || alertType}
    />
    <div className="row">
        <div ref={refDiv}/>
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="nombre" className="form-label">Nombre *</label>
                                    <input type="text" className="form-control" id="nombre" 
                                    {...register('nombre', {
                                        required: true,
                                        minLength: 5,
                                        maxLength: 160
                                    })} />
                                    <Alert
                                        tipo_alert="danger"
                                        type={errors.nombre?.type}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="ruc" className="form-label">Ruc *</label>
                                    <input type="text" className="form-control" id="ruc" 
                                        {...register('ruc', {
                                            required: true,
                                            minLength: 10,
                                            maxLength: 13
                                        })} />
                                    <Alert
                                        tipo_alert="danger"
                                        type={errors.ruc?.type}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="razon_social" className="form-label">Razon Social *</label>
                                    <input type="text" className="form-control" id="razon_social" 
                                    {...register('razon_social', {
                                        required: true,
                                        minLength: 5,
                                        maxLength: 160
                                    })} />
                                    <Alert
                                        tipo_alert="danger"
                                        type={errors.razon_social?.type}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="direccion" className="form-label">Direccion *</label>
                                    <input type="text" className="form-control" id="direccion" 
                                    {...register('direccion', 
                                        {
                                            required: true,
                                            minLength: 5,
                                            maxLength: 160
                                        })} />
                                    <Alert
                                        tipo_alert="danger"
                                        type={errors.direccion?.type}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="responsable" className="form-label">Responsable *</label>
                                    <input type="text" className="form-control" id="responsable" 
                                    {...register('responsable', 
                                    {
                                        required: true,
                                        minLength: 5,
                                        maxLength: 160
                                    })} />
                                    <Alert
                                        tipo_alert="danger"
                                        type={errors.responsable?.type}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="telefono" className="form-label">Telefono *</label>
                                    <input type="text" className="form-control" id="telefono" 
                                    {...register('telefono', {required: true})} />
                                    <Alert
                                        tipo_alert="danger"
                                        type={errors.telefono?.type}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="correo_electronico" className="form-label">Correo Electronico *</label>
                                    <input type="text" className="form-control" id="correo_electronico" {...register('correo_electronico', {required: true})} />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label htmlFor="medico_auditor" className="form-label">Medico Auditor *</label>
                                    <SelectSearchble
                                        listCompany={doctores} 
                                        handleChange={handleChange} 
                                        selectedOption={selectedOption} 
                                        placeholder="Seleccione un medico"
                                    />
                                </div>
                            </div>
                            <div className="form-check form-switch">
                                <div className="form-check form-switch">
                                    <label htmlFor="fecha_corte" className="form-label">Fecha Corte</label>
                                    <input type="checkbox" className="form-check-input" id="fecha_corte" 
                                    {...register('fecha_corte', {
                                        required: false,
                                        defaultChecked: false

                                    })} />
                                </div>
                                <div className="form-check form-switch">
                                    <input type="checkbox" className="form-check-input" id="estado" 
                                    {...register('estado', {
                                        required: false,
                                        defaultChecked: false
                                    })} />
                                    <label htmlFor="estado" className="form-label">Estado</label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button 
                                type="button"
                                onClick={()=>handleClose()}
                                className="btn btn-danger m-1">
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-primary">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 btnSppinAjax" 
                                style={{
                                    display: send ? 'inline-block' : 'none' 
                                }}></i>
                                Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}

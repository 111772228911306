
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { ControllerObtenerProcedimientosPendientes } from '../../../Service/Service.porcedimientos';
import { GetProcedimientoFactura, SetProcedimientoFactura } from '../../../Service/Service.Store';

export default function ModalProcedimientosPendientes({show, handleClose, ping, setPing, idpaciente, Procedimientos, setlocalPing}) {

  const [procedimientos, setProcedimientos] = useState([]);

  useEffect(() => {
    (async () => {
      if (show && idpaciente) {
        const result = await ControllerObtenerProcedimientosPendientes(idpaciente);
        if (result.success) {
          setProcedimientos(result.data);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text:
              result.message ||
              "Hubo un error al obtener los procedimientos",
          });
        }
      }
    })()
  }, [show])

  return (
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size='lg'
    >
          <Modal.Header closeButton>
            <Modal.Title>Procedimientos Pendientes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* una table para listar los procedimeintos pendientes */}
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Descripción</th>
                  <th>Esatdo</th>
                  <th>Precio</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  procedimientos.map(p => {
                    if(GetProcedimientoFactura().find(pf => pf.idprocedimiento_cita_paciente === p.idprocedimiento_cita_paciente)){
                      return null;
                    }else{
                      return (
                        <tr key={p.id}>
                          <td>{p.ExamenTipo.codigo}</td>
                          <td>{p.ExamenTipo.descripcion}</td>
                          <td>{p.estado}</td>
                          <td>{p.precio}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => {
                                // console.log("Agregar procedimiento", p);
                                SetProcedimientoFactura(p);
                                setlocalPing(Math.random());
                              }}
                            >
                              Agregar
                            </Button>
                          </td>
                        </tr>
                      )
                  }
                })
              }
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Modal.Footer>
    </Modal>
  )
}

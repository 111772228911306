


import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { ControllerCrearPlanSeguro } from '../../../Service/Services.seguros';

export default function ModalCreaPlan({showCreaPlan, handlecreaPlanClose, editarPlan, idseguro, setPing, ping}) {

    const [plan, setPlan] = useState({
        idseguro: 0,
        plan: '',
        estado: '',
        copago: 0,
    })
    useEffect(() => {
        if(idseguro){
            setPlan({...plan, idseguro: idseguro})
        }
    }, [idseguro])

    useEffect(() => {
        if(editarPlan){
            setPlan(editarPlan)
        }
    }, [editarPlan])


    const GuardarPlan = (e) => {
        e.preventDefault()
        if(plan.plan !== '' && plan.copago !== 0){
            Swal.fire({
                title: 'Estas seguro de guardar este plan?',
                text: 'No podras revertir esta accion!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, Guardar!'
              }).then(async(result) => {
                if (result.isConfirmed) {
                    console.log(plan);
                    const response = await ControllerCrearPlanSeguro(plan)
                    if(response){
                        Swal.fire(
                            'Guardado!',
                            'El plan ha sido guardado.',
                            'success'
                        )
                        handlecreaPlanClose()
                        setPing(!ping)
                    }else{
                        Swal.fire(
                            'Error!',
                            'Ha ocurrido un error.',
                            'error'
                        )
                    }
                }
              })
            return
        }
    }



  return (
    <div className="col-12">
    <Modal show={showCreaPlan} onHide={handlecreaPlanClose}>
      <Modal.Header >
        <Modal.Title>Plan de Seguro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
            <form>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>Nombre del Plan</label>
                            <input type="text" className="form-control" placeholder="Nombre del Plan"
                            value={plan.plan}
                            onChange={(e)=>setPlan({...plan, plan: e.target.value})} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label>Copago</label>
                            <input type="number" className="form-control" placeholder="Copago" 
                            value={plan.copago}
                            onChange={(e)=>setPlan({...plan, copago: e.target.value})}
                            />
                        </div>
                    </div>

                    <div className="col-12">
                    <div className="form-group">
                        <button type="submit" className="btn btn-primary"
                        onClick={GuardarPlan}
                        >Guardar</button>
                    </div>
                    </div>
                </div>
            </form>
        </div>

      </Modal.Body>
    </Modal>
    </div>
  )
}

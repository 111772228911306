/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { ListarClinicas } from "../../Service/Serive.clinica";
import { ObtenerCompanies } from "../../Service/Service.compania";
import { Crearuser } from "../../Service/Service.users";
import { SubirMedia } from "../../Service/ServiceExternal";
import { GetTokenDecoded } from "../../utils/storeUsuario";
import SelectSearchble from "../SelectSeachble/SelectSearchble";

export default function AdministrativoCreate({user, setUser, handleClose, ping, setPing}) {

  const [selectedOption, setSelectedOption] = useState(null);
  const [listarClinicas, setListarClinicas] = useState([])
  const [copypwd, setCopypwd] = useState(user.pwd)
  const [listCompany, setListCompany] = useState([])
  const [selectedOptionCompania, setSelectedOptionCompania] = useState(null);

  const handleInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  const CargarAvatar = async(file) => {
    const url = await SubirMedia(file, null, null, 0, 'avatar')
    setUser({
      ...user,
      foto_user: url
    })
  }

  const handleChangeCompania = selectedOptionCompania => {
    setSelectedOptionCompania(selectedOptionCompania);
    setUser({
      ...user,
      idcompany: selectedOptionCompania.value
    })
  };

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    setUser({
      ...user,
      users_clinicas: selectedOption.map(option => option.value),
      idclinica: selectedOption[0].value,
      idcompany: selectedOption[0].idcompany
    })
  };


  useEffect(() => {
    (async() => {
      if(ping){
        const cli = await ListarClinicas()
        if(cli.length > 0){
          if(user.users_clinicas.length > 0){
            const clini  = user.users_clinicas.map(clinica => cli.find(c => c.idclinica === clinica.idclinica))
            setSelectedOption(clini.map(clinica => ({
              value: clinica.idclinica,
              label: clinica.nombre,
              idcompany: clinica.idcompany
              
            })))
          }else{
            setListarClinicas(cli.map(clinica => ({
              value: clinica.idclinica,
              label: clinica.nombre,
              idcompany: clinica.idcompany
            })))
          }
        }
        const response = await ObtenerCompanies()
        if(response){
            response.map(company => {
              company.value = company.idcompany
              company.label = company.company
            })
            setListCompany(response)
        }
      }
    })()
  }, [])


  const GuardarUsuario = async(e) => {
    e.preventDefault()
    if(copypwd === user.pwd){
      user.pwd = null
    }
    const cr = await Crearuser(user)
    if(cr.success){
      setPing(!ping)
      handleClose()
    }
  }

  return (
    <section>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <form id="form-create-asesor">
                {
                  GetTokenDecoded().user_level === "superadmin" ? (
                    <div className="row mb-4">
                    <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Compania</label>
                    <div className="col-sm-9">
                      <SelectSearchble 
                        listCompany={listCompany} 
                        handleChange={handleChangeCompania} 
                        selectedOption={selectedOptionCompania} 
                        placeholder="Seleccione Compania"
                        isMulti={false}
                      />
                    </div>
                  </div>
                  ):null
                }
                    <div div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Clinicas *</label>
                      <div className="col-sm-9">
                        <SelectSearchble 
                          listCompany={listarClinicas} 
                          handleChange={handleChange} 
                          selectedOption={selectedOption} 
                          placeholder="Seleccione clinicas"
                          isMulti={true}
                        />
                      </div>
                    </div>
                    <div div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombres completos *</label>
                      <div className="col-sm-9">
                        <input type="text" 
                        value={user.full_name}
                        className="form-control" name="full_name" id="full_name" required onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Nombres de usuario</label>
                      <div className="col-sm-9">
                        <input type="text" 
                        value={user.user_name}
                        className="form-control" name="user_name" id="user_name" onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Usuario Email *</label>
                      <div className="col-sm-9">
                        <input type="text" 
                        value={user.user_email}
                        className="form-control" name="user_email" id="user_email" required onChange={handleInputChange} />
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">Clave *</label>
                      <div className="col-sm-9">
                        <input type="password" 
                        value={user.pwd}
                        className="form-control" name="pwd" id="pwd" required onChange={handleInputChange}/>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label for="horizontal-firstname-input" className="col-sm-3 col-form-label">avatar</label>
                      <div className="col-sm-9">

                        <div className="input-group">
                          <span className="input-group-btn">
                            <span className="btn btn-default btn-file">
                              Browse… <input type="file" id="imgInp" name="imgInp" accept="image/gif, image/jpeg, image/png" onChange={(e) => CargarAvatar(e.target.files[0])} />
                            </span>
                          </span>
                        </div>
                        <br/>
                          <input type="hidden" id="hlogo" name="hlogo" />
                            <div align="left">
                                {
                                    user.foto_user === '' ? <img src="https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg" id="img-upload" className="img-thumbnail" data-holder-rendered="true" style={{maxWidth: '250px'}} /> : 
                                    <img  src={user.foto_user} id='img-upload' className="img-thumbnail" data-holder-rendered="true" style={{maxWidth: '250px'}} />
                                }
                            </div>
                          </div>
                      </div>

                <div class="text-center">
                  <a href="#" class="btn btn-danger btnCloseCanvas">Cancelar</a>
                  <button type="submit" class="btn btn-primary ms-2"
                    onClick={GuardarUsuario}
                  >Guardar</button>
                </div>

              </form>


            </div>
          </div>
        </div> 
      </div> 


    </section>
  );
}
